import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import hex5_together from "../images/hex5_together.png";
import useStyles from "../styles/styles";
import ScrollAnchor from "./ScrollAnchor";
import { Fade } from "react-awesome-reveal";

const Together = () => {
  const classes = useStyles();

  return (
    <Fade>
      <div>
        <ScrollAnchor id="together" />
        <Box className={classes.aboutUsContainer}>
          <Grid
            container
            spacing={6}
            className={classes.gridContainer}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <img
                src={hex5_together}
                alt="My Team"
                className={classes.largeImage}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="h4"
                fontWeight={700}
                className={classes.title}
              >
                Together we build a stronger community
              </Typography>
              <Typography
                variant="h5"
                fontWeight={700}
                className={classes.title}
              >
                Organizers + Vendors + Attendees
              </Typography>

              <Typography variant="h6" className={classes.aboutUsSubtitle}>
                Hex5 platform fosters collaboration between organizers, vendors,
                and attendees to create memorable events. Our goal is to empower
                popup vendors to find legitimate events and take the hassle out
                of event planning for organizers. Hex5 app offers a unique way
                for event-goers to explore the culture and lifestyle of
                different communities, providing an immersive experience that
                makes them feel like a local.
              </Typography>

              <a
                href="#waitinglist"
                target="_self"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  // set font color to primary
                  sx={{
                    width: "300px",
                    fontSize: "16px",
                    fontWeight: "600",
                    borderRadius: "10px",
                    color: "primary.main",
                  }}
                >
                  Join our growing community
                </Button>
              </a>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Fade>
  );
};

export default Together;
